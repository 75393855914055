import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            cname: "首页",
            ename: "HOMEPAGE",
            title: "艺术留学_艺术留学作品集辅导培训中介机构-ROSSO官网",
            content: {
                keywords: "艺术留学,作品集培训,艺术留学辅导,艺术留学中介",
                description: "ROSSO创立于英国伦敦艺术学院，专注艺术留学申请、作品集培训，名校名师多对一辅导，拥有上海、北京、广州、深圳、成都、武汉、南京、杭州、东京等校区，助数千艺术学子圆梦世界名校。"
            },

        },
        component: HomeView
    },


    {
        path: '/major.html',
        name: 'majors',
        meta: {
            cname: "艺术专业",
            ename: "ART MAJOR",
            title: "艺术设计类专业_艺术类专业留学-ROSSO艺术留学",
            content: {
                keywords: "艺术类留学,艺术专业留学",
                description: "ROSSO艺术留学机构专门针对艺术类专业做留学培训作品集,对国外艺术类院校做最全面的规划与申请！"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/MajorsView.vue')
        }
    },
    {
        path: "/gdzpj.html",
        name: 'courses',
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO艺术留学-专业的艺术留学生课程培训机构",
            content: {
                keywords: "ROSSO 艺术留学 作品集培训机构",
                description: "ROSSO专注艺术留学及作品集培训，提供专业美国、英国、意大利等多国艺术生留学服务，祝您成功圆梦艺术名校。"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/CoursesView.vue')
        },


    },
    {
        path: '/teacher.html',
        name: 'tutors',
        meta: {
            cname: "师资团队",
            ename: "ART TUTOR",
            title: "「艺术专业留学」艺术留学导师_留学作品集咨询-ROSSO艺术留学",
            content: {
                keywords: "艺术留学培训,作品集培训导师,作品集培训",
                description: "「艺术专业留学」艺术留学作品集培训导师,海归名校导师咨询,海外名校招生官+教授亲临指导,多对一教学辅导,可获取TOP院校教授推荐信,提高艺术留学奖学金申请率,获取奖学金几率高达85%以上"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/TutorsView.vue')
        }
    },
    {
        path: '/school-0-0.html',
        name: 'school-0-0',
        meta: {
            cname: "名校导航",
            ename: "ART SCHOOL",
            title: "全部国家艺术大学排名/艺术学院排名/留学院校库-综合排名_ROSSO官网",
            content: {
                keywords: "全部国家艺术大学排名/艺术学院排名",
                description: "留学院校库-综合排名_ROSSO官网"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/SchoolsView.vue')
        }
    },
    {
        path: '/news.html',
        name: 'news',
        meta: {
            cname: "留学资讯",
            ename: "ART NEWS",
            title: "全部资讯_ROSSO官网",
            keepAlive: true,
            scrollTop: "",
            content: {
                keywords: "全部资讯/ROSSO官网",
                description: "全部资讯_ROSSO官网"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
        }
    },
    {
        path: '/promoting.html',
        name: 'promoting',
        meta: {
            cname: "背景提升",
            ename: "PROMOTING",
            title: "ROSSO官网_背景提升",
            content: {
                keywords: "ROSSO官网/背景提升",
                description: "ROSSO官网_背景提升"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/PromotingView.vue')
        }
    },
    {
        path: '/schools/:id',
        name: 'schoolsDetail',
        meta: {
            cname: "名校导航",
            ename: "ART SCHOOL",
            title: "ROSSO官网_院校详情",
            content: {
                keywords: "艺术留学申请",
                description: "是一所集艺术专业与设计学科为一体的艺术学院.院校风格偏学术研究为主,RISD大学艺术专业排名都很高,提供"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/SchoolsDetail.vue')
        },
    },
    {
        path: '/teachers/:id',
        name: 'teachersDetail',
        meta: {
            cname: "师资团队",
            ename: "ART TUTOR",
            title: "ROSSO官网_导师详情",
            content: {
                keywords: "艺术留学培训,作品集培训导师,作品集培训",
                description: "「艺术专业留学」艺术留学作品集培训导师,海归名校导师咨询,海外名校招生官+教授亲临指导,多对一教学辅导,可获取TOP院校教授推荐信,提高艺术留学奖学金申请率,获取奖学金几率高达85%以上"
            },
        },
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/TeachersDetail.vue')
        },
    },
    {
        path: '/majors/:id',
        name: 'majorsDetail',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/MajorsDetail.vue')
        },
        meta: {
            cname: "艺术专业",
            ename: "ART MAJOR",
            title: "ROSSO官网_专业详情",
            content: {
                keywords: "艺术类留学,艺术专业留学",
                description: "ROSSO艺术留学机构专门针对艺术类专业做留学培训作品集,对国外艺术类院校做最全面的规划与申请！"
            },
        },

    },
    {
        path: '/news/:id',
        name: 'newsDetail',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/NewsDetail.vue')
        },
        meta: {
            cname: "留学资讯",
            ename: "ART NEWS",
            title: "ROSSO官网_资讯详情",
           
            content: {
                keywords: "",
                description: ""
            },
        },

    },
    {
        path: '/PortfolioInfo/grand_master.html',
        name: 'grand_master',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/alevel.vue')
        },
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO官网_grand_master课程",
            content: {
                keywords: "作品集培训,ROSSO艺术留学,作品集辅导",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保证，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/PortfolioInfo/foundation.html',
        name: 'foundation',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/gagd.vue')
        },
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO官网_foundation课程",
            content: {
                keywords: "作品集培训,ROSSO艺术留学,作品集辅导",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保证，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/PortfolioInfo/aesthetics_promoting.html',
        name: 'aesthetics_promoting',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/ap.vue')
        },
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO官网_ap课程",
            content: {
                keywords: "作品集培训,ROSSO艺术留学,作品集辅导",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保证，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/PortfolioInfo/RossoCollege.html',
        name: 'RossoCollege',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/RossoCollege.vue')
        },
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO官网_rossoCollege课程",
            content: {
                keywords: "作品集培训,ROSSO艺术留学,作品集辅导",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保证，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/PortfolioInfo/vip.html',
        name: 'vip',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/vip.vue')
        },
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO官网_vip课程",
            content: {
                keywords: "作品集培训,ROSSO艺术留学,作品集辅导",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保证，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/PortfolioInfo/art_management.html',
        name: 'art_management',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Btec.vue')
        },
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO官网_art_management课程",
            content: {
                keywords: "作品集培训,ROSSO艺术留学,作品集辅导",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保证，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/PortfolioInfo/professional_portfolio.html',
        name: 'professional_portfolio',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Sample.vue')
        },
        meta: {
            cname: "艺术课程",
            ename: "ART COURSE",
            title: "ROSSO官网_professional_portfolio课程",
            content: {
                keywords: "作品集培训,ROSSO艺术留学,作品集辅导",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保证，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/lxgl.html',
        name: 'lxgl',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/lxgl.vue')
        },
        meta: {
            cname: "LXGL",
            ename: "",
            title: "ROSSO作品集培训_教学质量有保障＋每年超百学生成功案例",
            content: {
                keywords: "作品集培训 ROSSO国际艺术教育 ROSSO 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 英国皇家艺术学院 罗德岛艺术学院 纽约视觉艺术学院 加州艺术学院 普瑞特设计学院 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构",
                description: "「ROSSO」作品集培训课程，1对1定制教学方案|海归导师1对1授课|海外名校教授授课，让艺术零基础|转艺术专业|艺术专业进阶的学生，都能做出优质艺术留学作品集，成功拿到理想院校offer。ROSSO教学质量有保障，每年数百名学生成功案例可查询。ROSSO国际艺术教育 艺术留学培训 艺术留学 艺术升学作品集 作品集排版 作品集模板 插画 平面设计 服装设计 建筑设计 室内设计 工业设计 中央圣马丁 珠宝设计 帕森斯 伦敦艺术大学 上海_作品集培训机构 南京_作品集培训机构 成都_作品集培训机构"
            },
        },

    },
    {
        path: '/case-0-1-0.html',
        name: 'caseView',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/CaseView.vue')
        },
        meta: {
            title: "ROSSO官网_成功案例",
            content: {
                keywords: "",
                description: ""
            },
        }

    },
    {
        path: '/case/:id',
        name: 'caseDetail',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/CaseDetail.vue')
        },
        meta: {
            title: "ROSSO官网_案例详情",
            content: {
                keywords: "",
                description: ""
            },
        }

    },
    {
        path: '/yslx.html',
        name: 'yslx',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/yslx.vue')
        },
        meta: {
            title: "ROSSO作品集培训_教学质量有保障＋每年超百学生成功案例",
            content: {
                keywords: "",
                description: ""
            },
        }

    },
    {
        path: '/yslx.html',
        name: 'yslx',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/yslx.vue')
        },
        meta: {
            title: "ROSSO作品集培训_教学质量有保障＋每年超百学生成功案例",
            content: {
                keywords: "",
                description: ""
            },
        }

    },
    {
        path: '/offer',
        name: 'ui',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/offer.vue')
        },
        meta: {
            title: "the hell with offer",
            content: {
                keywords: "",
                description: ""
            },
        }

    },
    {
        path: '/offer2023',
        name: 'ui',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/offer2023.vue')
        },
        meta: {
            title: "the hell with offer",
            content: {
                keywords: "",
                description: ""
            },
        }
    },

]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        if(savedPosition){
            return savedPosition;
        }
        // window.scrollTo(0, 0)
        return {x:0,y:0}

    }
})
router.beforeEach((to, from, next) => {
    // console.log(from, "0118888");
    from.meta.scroolTop = window.pageYOffset || document.documentElement.scrollTop;
    next();
    
    // if (to.matched.length === 0) {
    //     // console.log("404")
    //     next('/')
    // } else {
    //     next()
    // }
    // console.log(to);

})
router.afterEach((to, from) => {
    if(from.meta.scrollTop != undefined){
        console.log("jin 0118");
        window.scroll({
            top:from.meta.scroolTop,
            behavior:"smooth"
        })
    }

    if (to.path == "/offer" || to.path == "/offer2023") {
        var navui = document.getElementsByClassName("navUi_hid")

        setTimeout(() => {
            var kefuui = document.getElementById("div_company_mini")
            // console.log("ui 1206",icon_module);
            kefuui.style.width = "0"
            icon_module.style.display = "none"
        }, 1500)
        for (var i = 0; i < navui.length; i++) {
            navui[i].style.display = "none"
        }

    }

    if (to.meta.content) {     //路由发生变化时候修改meta中的content
        let head = document.getElementsByTagName('head')
        let meta = document.createElement('meta')
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content
        head[0].appendChild(meta)
    }

    if (to.name == "schoolsDetail" && from.name == "school-0-0") {
        document.title = to.query.cname + "/研究生申请条件/一年费用/专业/排名-ROSSO官网"
        let head = document.getElementsByTagName('head')
        let meta = document.createElement('meta')
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.query.cname + to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.query.cname + to.meta.content.description + to.query.cname + "本科/研究生申请/学费")
        meta.content = to.meta.content
        head[0].appendChild(meta)

        // meta.content.keywords = to.meta.content.keywords + ""
    } else if (to.name == "newsDetail") {
        // console.log(to);
        // setTimeout(()=>{
        //   console.log(document.getElementsByClassName("nd1_big_title")[0]);
        //   document.title = document.getElementsByClassName("nd1_big_title")[0].textContent
        // })
        document.title = to.meta.title
    } else {
        document.title = to.meta.title
    }

    // var titleCn = document.getElementsByClassName("nav_client_cn")[0]
    // var titleEn = document.getElementsByClassName("nav_client_en")[0]

    // if (titleCn) {

    //     titleCn.innerHTML = to.meta.cname || ""
    //     titleEn.textContent = to.meta.ename || ""

    // }


})

export default router
